import { gql, useQuery } from '@apollo/client';
import { Alert, CircularProgress, Grid, styled } from '@mui/material';
import { OAuthCallbackRoute, PrivateRoute, useAuthentication } from '@onemedical/auth';
import MicroFrontend from '@onemedical/micro-frontend';
import * as Sentry from '@sentry/react';
import { Redirect, Route } from 'react-router-dom';

import mixpanel from '../mixpanel';
import CommandPalette from './CommandPalette';
import NavBar from './NavBar';
import { PendoUserFields, usePendo } from './hooks/Pendo';
import { useLaunchDarkly } from './hooks/LaunchDarkly';
import { InternalUser } from './models/InternalUser';
import { ProfileProvider } from './hooks/useProfile';
import { ProviderStatusProvider } from './hooks/useProviderStatus';
import ObservabilityWrapper from './wrappers/ObservabilityWrapper';

import hostedApps from '../data/hostedApps';

const APP_DATA = gql`
  query AppData {
    profile {
      id
      roles {
        id
        normalizedId
        name
      }
      ...PendoUserFields
      ...NavBarProfile
    }
    zendeskToken
  }
  ${PendoUserFields}
  ${NavBar.fragments.profile}
`;

interface AppData {
  profile: InternalUser;
  zendeskToken: string;
}

const StyledGrid = styled(Grid)(() => ({
  height: '100vh',
  width: '100%',
}));

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [],
});

function App() {
  const [, { authenticating, authenticated, error: authError }] = useAuthentication();
  const launchdarkly = useLaunchDarkly();
  const pendo = usePendo();
  const { data, loading } = useQuery<AppData>(APP_DATA, {
    skip: !authenticated,
  });
  if (data?.profile) {
    launchdarkly.identify(data.profile);
    mixpanel.identify(data.profile.id);
    pendo.initialize(data.zendeskToken, data.profile);
  }

  const params = new URLSearchParams(window.location.search);
  const view = params.get('view');

  const showLoadingScreen = authenticating || authError || loading;

  if (showLoadingScreen) {
    return (
      <StyledGrid container>
        <Grid item container justifyContent="center" alignItems="center" direction="column">
          {authError ? (
            <Grid item>
              <Alert severity="error">{authError}</Alert>
            </Grid>
          ) : (
            <>
              <Grid item>
                <CircularProgress />
              </Grid>
              <Grid item>
                <div>{authenticating && 'Checking credentials...'}</div>
              </Grid>
            </>
          )}
        </Grid>
      </StyledGrid>
    );
  }

  return (
    <ObservabilityWrapper>
      <CommandPalette />
      <OAuthCallbackRoute />
      {/* legacy routes used by onelife (to be deleted) */}
      <Route
        path={['/contacts', '/contact', '/provider-directory']}
        render={({ location }) => <Redirect to={`/admin${location.pathname}${location.search}`} />}
      />
      <Route
        path={['/appointments', '/phlebotomy-visits', '/provider-schedule']}
        render={({ location }) => (
          <Redirect to={`/schedule${location.pathname}${location.search}`} />
        )}
      />
      {/* end of legacy routes */}
      <Route exact path="/">
        {authenticated && <Redirect to="/admin" />}
      </Route>
      <PrivateRoute path="/">
        {data?.profile && view !== 'print' && (
          <ProfileProvider>
            <ProviderStatusProvider>
              <NavBar profile={data.profile} />{' '}
            </ProviderStatusProvider>
          </ProfileProvider>
        )}
      </PrivateRoute>
      {hostedApps.map(({ id, path, host }) => (
        <Route
          key={id}
          path={path}
          render={({ history }) => (
            <MicroFrontend
              id={id}
              host={host}
              history={history}
              containedAppProps={data} // used to trigger a refresh on profile change
              onError={(error) => {
                console.error(`Failed to load '${id}' micro-frontend`, error); // eslint-disable-line no-console
              }}
            >
              <StyledGrid container justifyContent="center" alignItems="center" direction="column">
                <Grid item>
                  <CircularProgress />
                </Grid>
              </StyledGrid>
              {Sentry.setTag('container_frontend', id)}
            </MicroFrontend>
          )}
        />
      ))}
    </ObservabilityWrapper>
  );
}

export default App;
