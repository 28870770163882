import { ReactNode, useEffect, useRef } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import createAuthenticatedHttpLink from '@onemedical/graphql-auth';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import hostedApps from '../../data/hostedApps';

/**
 * initialize apollo headers
 * @param enableDefaultHeaders the feature flag
 * @returns
 */
const createInitialClient = () => {
  const initialHeaders = {
    'X-platform': 'onelife-container',
    'X-application-id': 'onelife-container',
    'X-application-version': process.env.GIT_HASH ?? 'unknown',
  };
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        InternalUser: {
          fields: {
            roles: {
              merge(_, incoming: []) {
                return incoming;
              },
            },
          },
        },
      },
    }),
    link: createAuthenticatedHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_API_URL,
      headers: initialHeaders,
    }),
  });
};

// wrapper component to manage apollo client
export default function ApolloWrapper({ children }: { children: ReactNode }) {
  const { onelifeContainerEnableGraphqlSourceHeaders } = useFlags();

  const location = useLocation();
  // obtain the repo from the pathname
  const pathName = location.pathname.split('/')[1];
  const repoId = hostedApps.find((app) => app.path === `/${pathName}`)?.repo ?? 'onelife-container';

  const clientRef = useRef(createInitialClient());

  useEffect(() => {
    let headers: Record<string, string> = {};
    if (onelifeContainerEnableGraphqlSourceHeaders) {
      // when the app id changes, change the headers and attach them to GraphQL requests
      headers = {
        'X-platform': 'onelife-container',
        'X-application-id': repoId,
      };

      if (repoId === 'onelife-container') {
        headers['X-application-version'] = process.env.GIT_HASH ?? 'unknown';
      }
    }
    const newLink = createAuthenticatedHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_API_URL,
      headers,
    });
    clientRef.current.setLink(newLink);
  }, [repoId, onelifeContainerEnableGraphqlSourceHeaders]);

  return <ApolloProvider client={clientRef.current}>{children}</ApolloProvider>;
}
